import { Icon } from '../Icon.js'

const SwitchWrapper = styled.button({
  className: `
    inline-flex
    items-center
    flex-wrap
    relative
    gap-[1ch]
    cursor-pointer
    select-none
    has-disabled:cursor-normal
    group/switch
  `,
  attributes: {
    role: 'switch',
    type: 'button',
  },
})

const SwitchTrack = styled.span({
  className: [`
    inline-flex
    items-center
    justify-around
    h-5
    w-10
    box-content
    border-2
    border-transparent
    relative
    rounded-full
    flex-shrink-0
    bg-gray-200

    before:size-5
    before:rounded-full
    before:box-border
    before:forced-colors:outline-1
    before:absolute
    before:top-0
    before:left-0
    before:ring-0
    before:z-[1]
    before:bg-white
    before:shadow
    motion-safe:before:transition-all
    motion-safe:before:duration-200

    focus-visible:focus-within:ring-2
    focus-visible:focus-within:ring-indigo-600
    focus-visible:focus-within:ring-offset-2

    group-[[aria-checked="true"]]/switch:before:translate-x-full
    group-[[aria-checked="true"]]/switch:bg-blue-500
    group-disabled/switch:opacity-60
    group-disabled/switch:pointer-events-none
  `],
  attributes: {
    role: 'switch',
    // 'aria-checked': checked,
  },
})

type SwitchButtonProps = {
  checked: boolean
  children: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: (event: Dx.Event.Mouse<'button'>) => void
  label?: {
    hide?: boolean
    position?: 'before' | 'after'
  }
}

export default function SwitchButton(props: SwitchButtonProps) {
  const {
    checked,
    children,
    label: {
      hide = false,
      position = 'after',
    } = {},
    ...rest
  } = props

  return (
    <SwitchWrapper
      aria-checked={checked}
      {...rest}>
      {position === 'before' && <span className={classNames({ 'sr-only': hide })}>{children}</span>}
      <SwitchTrack>
        <Icon name='check' className='text-white size-3' />
        <Icon name='x' className='text-gray-500 size-3' />
      </SwitchTrack>

      {position === 'after' && <span className={classNames({ 'sr-only': hide })}>{children}</span>}
    </SwitchWrapper>
  )
}